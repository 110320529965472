.post-password-settings {
  &-inner {
    .post-form-bundle {
      margin-bottom: 16px;
      &-input {
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        padding: 8px;
        background-color: #ffffff;
        height: 40px;
        .form-input-action {
          width: 100%;
          border: none;
          padding: 0px;
          background-color: transparent;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
