@mixin paddings {
  &.sf-padding-0 {
    padding: 0px;
  }

  &.sf-padding-8 {
    padding: 8px;
  }

  &.sf-padding-16 {
    padding: 16px;
  }

  &.sf-padding-24 {
    padding: 24px;
  }

  &.sf-padding-32 {
    padding: 32px;
  }

  &.sf-padding-40 {
    padding: 40px;
  }
}
@mixin placeholder {
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $gray03;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $gray03;
  }
  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $gray03;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $gray03;
  }
}

@font-face {
  font-family: 'FazzNueue';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/Fonts/webfont/FazzNeue-Regular.eot');
  /* IE9 Compat Modes */
  src: local('FazzNueue'), local('FazzNueue'),
    url('/assets/Fonts/webfont/FazzNeue-Regular.eot')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/Fonts/webfont/FazzNeue-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/Fonts/webfont/FazzNeue-Regular.woff') format('woff'),
    /* Modern Browsers */ url('/assets/Fonts/ttf/FazzNeue-Regular.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'FazzNueue';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/Fonts/webfont/FazzNeue-Bold.eot');
  /* IE9 Compat Modes */
  src: local('FazzNueue'), local('FazzNueue'),
    url('/assets/Fonts/webfont/FazzNeue-Bold.eot') format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/Fonts/webfont/FazzNeue-Bold.woff2')
      format('woff2'),
    /* Super Modern Browsers */ url('/assets/Fonts/webfont/FazzNeue-Bold.woff')
      format('woff'),
    /* Modern Browsers */ url('/assets/Fonts/ttf/FazzNeue-Bold.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

@font-face {
  font-family: 'RobotoMono';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/Fonts/webfont/RobotoMono-Regular.eot');
  /* IE9 Compat Modes */
  src: local('RobotoMono'), local('RobotoMono'),
    url('/assets/Fonts/webfont/RobotoMono-Regular.eot')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/Fonts/webfont/RobotoMono-Regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/Fonts/webfont/RobotoMono-Regular.woff') format('woff'),
    /* Modern Browsers */ url('/assets/Fonts/ttf/RobotoMono-Regular.ttf')
      format('truetype');
  /* Safari, Android, iOS */
}

.fullscreen {
  width: 100%;
  height: 100%;
}
