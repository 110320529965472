.step-virtual-payment {
  .post-sided {
    position: relative;
    &:before {
      content: '';
      height: 100%;
      display: block;
      background-color: #e0e0e0;
      position: absolute;
      left: 12px;
      top: 24px;
      width: 1px;
    }
    &:last-child {
      &::before {
        display: none;
      }
    }
  }
}
