$white: #ffffff;
$black: #333333;
$blue: #3b8ef2;
$red: #ba1818;
$softgray: #f7f9fa;
$gray: #e0e0e0;
$brownGray: #999999;
$darkgray: #e1e4e6;
$darkgraybold: #979899;
$grayText: #4b4b4b;
$semiGray: #757575;
$primaryBlue: #001e3a;
$green: #18ba62;
$bluegray: #f5f8fa;
$yellow: #ffcc00;
$neutral: #f2f4f5;
