.sidebar-wrap {
  width: 265px;
  height: 100%;
  background-color: #333333;
  overflow-x: hidden;
}

.sidebar-wrap.v1 {
  background-color: #1d1d1f;
}

.sidebar-wrap.v1 .child-side {
  padding: 32px 24px 0 24px;
}

.sidebar-wrap > .sidebar.main-side {
  left: 0;
}

.sidebar-wrap > .sidebar.child-side {
  left: 240px;
}

.sidebar-wrap.child-mode > .sidebar.main-side {
  left: -240px;
}

.sidebar-wrap.child-mode > .sidebar.child-side {
  left: 0;
}

.sidebar-v3 {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  transition: all 0.25s ease;
  position: absolute;
  left: 0;
  width: 265px;
  z-index: 2;
  padding: 32px 0 0 0;
}

.sidebar-v3 .post-sidebar {
  visibility: visible;
}

.sidebar-v3 .post-sidebar-logo {
  margin-bottom: 40px;
  padding: 0 24px;
}

.sidebar-v3 .post-sidebar-profile {
  align-items: start;
  margin-bottom: 32px;
  padding: 0 24px;
}

.sidebar-v3 .post-sidebar-profile .post-sided {
  width: 100%;
  position: relative;
}

.sidebar-v3 .post-sidebar-profile .post-sided:first-child {
  cursor: pointer;
}

.sidebar-v3 .post-sidebar-profile .badge-status {
  left: 42px;
  position: absolute;
}

.sidebar-v3 .post-sidebar-profile-user {
  width: 33%;
}

.sidebar-v3 .post-sidebar-profile-user img.pict-circle {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.sidebar-v3 .post-sidebar-profile-info {
  padding-left: 10px;
}

.sidebar-v3 .post-sidebar-profile-info-name {
  font-weight: 600;
  font-size: 14px;
}

.sidebar-v3 .post-sidebar-profile-info-name-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.sidebar-v3 .post-sidebar-profile-info-name p {
  margin-bottom: 5px;
  font-family: 'FazzNueue', sans-serif;
}

.sidebar-v3 .post-sidebar-setup-guide {
  padding: 8px 24px 24px 24px;
}

.sidebar-v3 .post-sidebar-child-headtext {
  display: flex;
  cursor: pointer;
}

.sidebar-v3 .post-sidebar-child-headtext > .post-icon-arrows-left {
  background-color: #ffffff;
  margin-right: 16px;
}

.sidebar-v3 .post-sidebar-child-headtext .post-heading {
  color: #ffffff;
}

.sidebar-v3 .post-sidebar-child-menu > ul.ant-menu {
  margin-left: 40px;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu {
  background-color: transparent;
  border: none;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li {
  font-family: 'FazzNueue', sans-serif;
  padding: 16px 0;
  color: #979899;
  margin: 0;
  height: auto;
  line-height: initial;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li .post-icon.submenu-indicator {
  right: 0;
  position: absolute;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li.expandable {
  padding: 0;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li.expandable > ul {
  padding: 0;
  overflow: hidden;
  max-height: 100vh;
  margin-top: 0;
  margin-bottom: 0;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li.expandable > ul.shrink {
  max-height: 0;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li.ant-menu-item:active {
  background-color: transparent;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu li.ant-menu-item.selected {
  color: #ffffff;
  font-weight: bold;
}

.sidebar-v3 .post-sidebar-child-menu ul.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.sidebar-v3 .post-menu {
  scrollbar-width: thin;
  scrollbar-color: #757575 transparent;
}

.sidebar-v3 .post-menu ul {
  background: transparent;
  border: none;
  height: 100%;
}

.sidebar-v3 .post-menu ul > li.ant-menu-item {
  color: #979899;
  padding: 8px 0;
  margin: 0;
  height: 52px;
  font-family: 'FazzNueue', sans-serif;
}

.sidebar-v3 .post-menu ul > li.ant-menu-item:active {
  background: transparent;
}

.sidebar-v3 .post-menu ul > li.ant-menu-item.ant-menu-item-selected {
  font-weight: bold;
}

.sidebar-v3 .post-menu ul > li.ant-menu-item.ant-menu-item-selected .post-icon {
  background-color: #ffffff;
}

.sidebar-v3 .post-menu ul > li.ant-menu-item.ant-menu-item-selected .post-span {
  color: #ffffff;
}

.sidebar-v3 .post-menu ul > li .float-icon {
  float: right;
  margin-right: 0;
  top: 9px;
  position: relative;
}

.sidebar-v3 .post-menu ul.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}

.sidebar-v3 .post-menu .post-heading {
  padding: 0 24px;
}

.sidebar-v3 .post-menu-item {
  cursor: pointer;
  height: 40px;
  color: #979899;
  padding: 0 24px;
  font-weight: bold;
}

.sidebar-v3 .post-menu-item > .post-sided {
  height: 100%;
}

.sidebar-v3 .post-menu-item.is-active {
  background-color: #4b4b4b;
  border-left: solid 4px #3b8ef2;
  padding-left: 20px !important;
}

.sidebar-v3 .post-menu-item.is-active.children {
  padding-left: 44px !important;
}

.sidebar-v3 .post-menu-item.is-active .post-span {
  color: white;
}

.sidebar-v3 .post-menu-item.is-active .post-icon {
  background-color: white;
}

.sidebar-v3 .post-menu-item.children {
  padding-left: 48px;
  font-weight: 100;
}

.sidebar-v3 .post-menu-item:hover {
  background-color: #4b4b4b;
}

.sidebar-v3 .post-menu::-webkit-scrollbar {
  width: 10px;
}

.sidebar-v3 .post-menu::-webkit-scrollbar-track {
  background: transparent;
}

.sidebar-v3 .post-menu::-webkit-scrollbar-thumb {
  background: #757575;
  border-radius: 4px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

.sidebar-v3 .post-menu::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

.sidebar-v3 .post-menu::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sidebar-v3 .post-menu.list {
  overflow: auto;
  width: 100%;
  background: linear-gradient(#333 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #333 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background: linear-gradient(#333 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), #333 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-repeat: no-repeat;
  background-color: #333;
  background-size: 100% 40px, 100% 40px, 100% 20px, 100% 20px;
  /* Opera doesn't support this in the shorthand */
  background-attachment: local, local, scroll, scroll;
}

.sidebar-v3 .post-icon {
  vertical-align: middle;
  cursor: pointer;
}

.sidebar-v3 .post-icon.submenu-indicator {
  margin-right: 0;
}

.sidebar-v3 .circle-checklist {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 10px;
}

.sidebar-v3 .circle-checklist .post-icon {
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  mask-size: contain;
  -webkit-mask-size: contain;
}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.panel {
  height: 0;
  transition: height 0.25s ease-out;
  overflow: hidden;
}

.panel.visible {
  transition: height 0.25s ease-in;
}
