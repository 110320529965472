.post-loader-ring {
  &.relative {
    text-align: center;
  }

  &.absolute {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
  }

  display: inline-block;
  position: relative;
  width: 88px;
  height: 88px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 48px;
    height: 48px;
    margin: 20px;
    border: 4px solid #3b8ef2;
    border-radius: 50%;
    animation: post-loader-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #3b8ef2 transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  &.size-16 {
    width: 16px;
    height: 16px;

    div {
      width: 16px;
      height: 16px;
      margin: 0;
      border: 2px solid #3b8ef2;
      animation: post-loader-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #3b8ef2 transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }

  &.size-24 {
    width: 40px;
    height: 40px;

    div {
      width: 24px;
      height: 24px;
      margin: 8px;
      border: 4px solid #3b8ef2;
      animation: post-loader-ring 1.5s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #3b8ef2 transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}
// }
@keyframes post-loader-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
