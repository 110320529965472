body {
  font-family: 'FazzNueue', sans-serif;
  margin: 0;
  padding: 0;
}

body,
html {
  padding: 0;
  margin: 0;
}

#inner {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: left top;
}
